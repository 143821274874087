const employmentOptions =
{
    seniorityLevels: [
        "Student/Trainee",
        "Junior",
        "Middle",
        "Senior",
        "Lead",
        "Manager",
        "Director"
    ],
    platforms: [
        "PC",
        "Console",
        "Mobile",
        "VR",
        "AR",
        "Web",
        "Not applicable"
    ],
    employmentOptions : [
        "Full-time",
        "Part-time",
        "Freelance"
    ],
    artAndAnimationStyles: [
        "Anime & Manga",
        "Fantasy",
        "Hard Surface",
        "Horror",
        "Pixel and Voxel Art",
        "Realistic",
        "Sci-Fi",
        "Stylized"
    ],
    gameEngines: [
        "Unity",
        "Unreal Engine (UE)",
        "Amazon Lumberyard",
        "ARkit",
        "Blender",
        "Cocos2d",
        "Consolution",
        "Construct",
        "Corona SDK",
        "CryENGINE",
        "Frostbite",
        "GameMaker: Studio",
        "GameSalad",
        "Godot",
        "Havok",
        "Havok Vision Engine",
        "HeroEngine",
        "ID Tech",
        "Infinity Engine",
        "Kivy",
        "libGDX",
        "LÖVE",
        "Phaser",
        "PlayCanvas",
        "RAGE Engine",
        "RPG Maker VX Ace",
        "The Dark Engine",
        "Turbulenz"
    ], gameGenres: [
        "AR/Location Based",
        "Arcade",
        "Puzzle",
        "Life-style",
        "Simulation",
        "Hyper-casual",
        "Casino",
        "Shooter",
        "RPG",
        "Card Games",
        "Strategy",
        "Sports",
        "Driving",
        "Match 3"
    ],
    skills: [
        ".NET",
        "3D Animation",
        "3D Engine",
        "3D Max",
        "3D Modeling",
        "3D Printing",
        "3D Studio Max",
        "3D Visualisation",
        "AB testing",
        "Ad monetization",
        "Ad Tech",
        "AD-LDS",
        "Adobe Creative Suite",
        "Adobe Illustrator",
        "Adobe InDesign",
        "Adobe Photoshop",
        "Adobe Premier Pro",
        "Adobe XD",
        "Adventure Creator for Unity",
        "Advertising",
        "Advertising tech stack design",
        "After Effects",
        "Agile",
        "Algorithms and Data Structures",
        "Airbrush",
        "Analysis",
        "Anatomy",
        "Android SDK",
        "Android Studio",
        "Angular",
        "Animation",
        "Anka",
        "Ansible",
        "Apache JMeter",
        "Apache Kafka",
        "Apache Maven",
        "Apache Server",
        "Apache Spark",
        "API",
        "Apple App",
        "Applied Statistics",
        "Appium",
        "AR",
        "AR Core",
        "AR Kit",
        "Architectural Design",
        "Art Direction",
        "Articy:Draft 2",
        "Asana",
        "Atlassian toolchain IAM",
        "Audio Editing",
        "Audio Engineering",
        "Audio Occlusion",
        "Audio Post Production",
        "Audio Prioritization",
        "Audio Recording",
        "Audiokinetic Wwise",
        "Auth",
        "AutoCAD",
        "Automation testing",
        "AutoML",
        "AWS",
        "Azure",
        "Backend Development",
        "Balsamiq",
        "Bananatag",
        "Bash",
        "Behaviour Trees",
        "BI Tools",
        "Big Data",
        "Blender",
        "Blueprints",
        "Broadcasting",
        "Bug Tracking",
        "Bugzilla",
        "Business Analysis",
        "Business Process Improvement",
        "Business Strategy",
        "C",
        "C#",
        "C++",
        "Campaigns Management",
        "Campaigns Optimization",
        "CD",
        "CelAction",
        "Celtx",
        "CentOS",
        "CG",
        "Change Control",
        "Change Management",
        "Character Animation",
        "Character Art",
        "Character Designs",
        "Character Rigging",
        "ChoiceScript",
        "Chrome Dev Tools",
        "CI",
        "CI/CD",
        "Cinema 4D",
        "ClickHouse",
        "Cloud computing",
        "Cloud engineering",
        "Cloud platforms",
        "Cloud technologies",
        "Cloud-based services",
        "Cluster Analysis",
        "Clusters",
        "CMake",
        "Cocos",
        "Cocos2d-x",
        "Cocos2D/3D",
        "Coding",
        "Comic Arts",
        "Commercials",
        "Compositing",
        "Computer Graphics",
        "Computer-Aided Design (CAD)",
        "Concept Development",
        "Confluence",
        "Confluence",
        "Confluence Game features",
        "Construct 3",
        "Consumer Behaviour",
        "Container services",
        "Content Management System",
        "Continuous Improvement",
        "Cool Edit Pro",
        "Copywriting",
        "CoreData",
        "Corrective and Preventive Action (CAPA)",
        "CPU-Z",
        "Craft",
        "Creative Brief",
        "Creative Strategy",
        "Creature Design",
        "Creature Modeling",
        "Cross-broswer testing",
        "CSS",
        "Cucumber",
        "Cucumber BDD",
        "Customer Service",
        "Cypress",
        "Dart",
        "Dashboards",
        "Data Analysis",
        "Data Integration",
        "Data Mining",
        "Data Modeling",
        "Data Retrieval",
        "Data Security",
        "Data Visualisation",
        "Data Warehousing",
        "Database Design",
        "Database Management",
        "Database Systems",
        "Databricks Lakehouse",
        "Deep Learning",
        "Dialogue systems",
        "Digital Marketing",
        "Digital Painting",
        "Digital Sculpting",
        "Direct X",
        "Django",
        "Docker",
        "Documentaries",
        "Drawing",
        "Dropbox SDK",
        "Elasticsearch",
        "Elixir",
        "Email Marketing",
        "Engineering",
        "Entity-Component-Systems (ECS) framework",
        "Environment Design",
        "EPC",
        "EQS",
        "ETL",
        "Exploratory Data Analysis",
        "Express JS",
        "Facebook API",
        "Facial Rigging & Animation",
        "Failure Mode and Effects Analysis (FMEA)",
        "FastAPI",
        "Fastlane",
        "FeathersJS",
        "Fedora",
        "Field Recording",
        "Figma",
        "Film",
        "Film Production",
        "Final Cut Pro",
        "Financial Analysis",
        "Firebase",
        "Flask",
        "FlowMapp",
        "Flutter",
        "FMOD",
        "Fortran",
        "Framer X",
        "Free to Play (F2P)",
        "Functional Testing",
        "Game Art",
        "Game Design",
        "Game Development (GameDev)",
        "Game Events Planning",
        "Game Insights",
        "Game Mechanics",
        "Game Testing",
        "GameMaker Studio 2",
        "Gameplay",
        "Gameplay effects",
        "Gant",
        "GCP",
        "Gherkin",
        "Git",
        "Github",
        "GLSL",
        "Go",
        "Godot",
        "Golang or Go/golang",
        "Google Analytics",
        "Google BigQuery",
        "Google Cloud Platform",
        "Google Maps & API",
        "Google play",
        "GPU",
        "GPU-Z",
        "Gradle",
        "Grafana",
        "Graphic Design",
        "Graphical User Interface (GUI)",
        "GraphQL",
        "GRPC api",
        "Hadoop",
        "HealthKit",
        "Hero",
        "Hibernate",
        "Hive",
        "HLSL",
        "Houdini",
        "HP LoadRunner",
        "HTML",
        "HTTP",
        "Hybrid Apps",
        "HydraAsync & GCD",
        "Hypothesis Testing",
        "IAM",
        "IAP & Subscriptions",
        "IBM SPSS Statistics",
        "Iconic",
        "Illustration",
        "Industrial Design",
        "In-game dialogue",
        "Inform 7",
        "Information Architecture",
        "Information Retrieval",
        "Information Visualization",
        "Instagram API",
        "IntelliJ",
        "Interaction Design",
        "Internet Marketing",
        "InVision Studio",
        "Jaeger",
        "Java",
        "Java EE",
        "JavaScript (JS)",
        "Jenkins",
        "Jenkins Pipelines",
        "Jira",
        "jQuery",
        "JUnit",
        "JVM",
        "kafka",
        "Kotlin",
        "Kubernetes",
        "Lambda",
        "Layout Design",
        "LDAP",
        "Leadership",
        "Lean Production",
        "Level Design",
        "Lighting",
        "Linear Algebra",
        "Linux",
        "Logic Pro",
        "Logo Design",
        "Looker",
        "Lottie",
        "Lua",
        "Management",
        "Manual Testing",
        "Market Analysis",
        "Marketing",
        "Marketing Management",
        "Marketing Strategy",
        "Marvel",
        "Master Data Management",
        "Mathematical and Applied Statistics",
        "MatLab",
        "Maven",
        "Maya",
        "Mechanical Engineering",
        "MEL",
        "Menu-Driver User Interface",
        "Microservices",
        "Microsoft (MS) SQL Server",
        "Microsoft Excel",
        "Microsoft Project",
        "Microsoft SQL Server",
        "MMO",
        "Mocha",
        "Mockito",
        "Monetization",
        "MongoDB",
        "Mongoose",
        "Motion Capture",
        "Motion Graphics",
        "Moya & AFNetworking",
        "MR",
        "MSI Afterburner",
        "Mudbox",
        "Music Composition",
        "Music Production",
        "Music systems",
        "MVVM",
        "MyBatis",
        "MySQL",
        "Native iOS Development",
        "Negotiations",
        "NestJs",
        "Netsuite",
        "Network Security",
        "Nginx",
        "Node.js",
        "NoSQL",
        "NoSQL Database",
        "Notifications",
        "Nuclino",
        "Nuke",
        "Oasis360",
        "OAuth",
        "Obejective C",
        "Online Advertising",
        "Online Marketing",
        "Open GL",
        "OpenID Connect",
        "OpenLDAP",
        "Optimal Workshop",
        "Optimization",
        "Origami Studio",
        "PBR Texturing",
        "Perforce",
        "Perl",
        "Perspective",
        "Photography",
        "PHP",
        "Pixel Art",
        "Player Behavior",
        "Player expereince",
        "PlayStation Tools",
        "PMI / PMBOK",
        "PostgreSQL",
        "Postman",
        "PowerBI",
        "Powershell",
        "Predictive Analytics",
        "Primavera P6",
        "Pro Tools",
        "Product Development",
        "Product Engineering",
        "Product Management",
        "Product Marketing",
        "Programmatic Expertise",
        "Project Engineering",
        "Project Management",
        "Project Management",
        "Project Planning",
        "Prop Design",
        "Proto.io",
        "Prototyping",
        "Publishing",
        "Puppeteer",
        "Python",
        "Python Scripting",
        "Qlik Sence",
        "QlikView",
        "Quality Assurance (QA)",
        "Quality Auditing",
        "Quality Control",
        "Quality Management",
        "Quality System",
        "Quixel",
        "R",
        "React",
        "React Native",
        "Realm",
        "Reaper",
        "Recruitment",
        "Redis",
        "Regression",
        "Regression Analysis",
        "Regression Modeling",
        "Regression Testing",
        "Rendering",
        "Reporting Statistics and Patterns",
        "Requirements Analysis",
        "Requirements Gathering",
        "Research",
        "Resource Planning",
        "REST",
        "Rest Assured",
        "Reverse Engineering",
        "Revit",
        "Rigging",
        "Root Cause Analysis",
        "Rotoscoping",
        "RPG Maker",
        "Ruby",
        "Ruby on Rails",
        "RxCocoa",
        "RxDart",
        "RxSwift",
        "SAML2",
        "SAS Business Intelligence",
        "Scala",
        "Screenwriting",
        "Scuplting",
        "Scrivener",
        "Scrum",
        "SDK",
        "SDK Jenkins",
        "SDK Management",
        "Search (Engine) Optimization",
        "Selenide",
        "Selenium",
        "Sensor Tower",
        "SEO",
        "SEO Optimization",
        "Set Design",
        "Shaders",
        "Shell",
        "Site Audit",
        "Sketch",
        "Sketching",
        "Smali",
        "Smartsheets",
        "SOAP",
        "Social Media",
        "Sound Design",
        "Sound Forge",
        "SPA",
        "Spark",
        "Spring",
        "Spring Boot",
        "Spring Cloud",
        "Spring Data",
        "Spring Framework",
        "Spring JDBC",
        "Spring Kafka",
        "Sputnik",
        "SQL",
        "SQL Server Analysis Services (SSAS)",
        "SQL Server Integration Services (SSIS)",
        "SQL Server Reporting Services (SSRS)",
        "SQLite",
        "SQS",
        "SSO systems",
        "Stage Design",
        "Statistical Analysis Methods",
        "Statistical Modelling",
        "Statistical Programming",
        "Statistical Tools",
        "Statistics",
        "Storyboarding",
        "Strategic Planning",
        "Studio Management",
        "Substance Painter",
        "Swift",
        "System Testing",
        "Tableau",
        "Tasks",
        "Team Management",
        "Television",
        "Television",
        "Terraform",
        "Test Management",
        "Test Planning",
        "Testing",
        "Testrail",
        "Texture Painting",
        "Texturing",
        "Third-party SDK’s",
        "Ticket management",
        "Tomcat",
        "ToonBoom Harmony",
        "Traditional Animation",
        "Traditional Painting",
        "Treejack",
        "TVPaint",
        "Twine",
        "TypeScript",
        "Typography",
        "UI",
        "UI Design",
        "UI sounds",
        "Unit Testing",
        "Unity",
        "Unity 3D",
        "Unreal Blueprints",
        "Unreal Engine",
        "User Experience (UX)",
        "User Experience Design (UED)",
        "User Interface Design (UI Design)",
        "User testing",
        "User Unterface (UI)",
        "UV Mapping",
        "UX",
        "UXPin",
        "Various analytics",
        "Vehicle Moduling",
        "Version control system",
        "Version Control Systems",
        "Video",
        "Video Editing",
        "Video Post-Production",
        "Video Production",
        "VirtualBox",
        "Visio",
        "Visual design",
        "Visual Storytelling",
        "Visual Studio",
        "Visualization",
        "Visualizing Statistics and Patterns",
        "VisualSitemaps",
        "Voice Acting",
        "Voice User Interface (VUI)",
        "Vue.js",
        "Vulkan API",
        "Waterfall",
        "Weapon Design",
        "Weapon Modeling",
        "Web based services",
        "Web Design",
        "Web development",
        "Web testing",
        "Webflow",
        "Webpack",
        "Wildfly",
        "Wireframing",
        "Workday",
        "Wwise",
        "Xamarin",
        "Xbox Tools",
        "Xcode",
        "XML",
        "XMPP",
        "XR",
        "Yield Optimization",
        "Zbrush",
        "ZBrush",
        "Zeebe",
        "Zookeeper",
        "Zoology"
    ]
};
module.exports = employmentOptions;
