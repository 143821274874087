/**
 * Some common styles to be used in several places
 */
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  titleBar: {
    backgroundColor: "#81A199",
    // height: 148
  },
  titleCaptionsContainer: {
    maxWidth: "80%",
    width: 760,
    height: "100%"
  },
  titleCaptionsInnerContainer: {
    height: "100%"
  },
  titleLogoContainer: {
    maxWidth: "100vw" //for logo never to be wider than the view port
  },
  titleLogo: {
    // height: 148,
    maxWidth: "100%"
  },
  formWrapper: {
    maxWidth: 644,
    textAlign: "left",
    marginTop: 40
  },
  registrationForm: {
  },
  introText: {
    textAlign: "justify"
  },
  formLine: {
    width: "100%",
    paddingTop: 20
  },
  nonLineFormElement: {
    paddingTop: 20,
    width: "100%"
  },
  inputControl: {
    paddingTop: 9,
    paddingBottom: 9,
  },
  textFieldInput: {
    "& input": {
        paddingTop: 9,
        paddingBottom: 9,
    }
  },
  inputLabel: {
    fontWeight: "bold",
    color: "black",
    whiteSpace: "nowrap"
  },
  employmentOptionsLabel: {
    fontWeight: "bold",
    color: "black",
    whiteSpace: "break-spaces",
    position: "static"
  },
  inputLabelAsterisk: {
    color: "red"
  },
  autocompleteInputRoot: {
    paddingTop: "0 !important;"
  },
  chipRoot: {
   height: 24,
   borderRadius: 4
  },
  chipColorPrimary: {
    backgroundColor: "#2A7CE4"
  },
  fileInputControl: {
    backgroundColor: "rgba(0, 0, 0, 0.09)",
    textTransform: "initial",
    whiteSpace: "nowrap",
    justifyContent: "flex-start"
  },
  fileInputControlLabelFileNonSelected: {
    overflow: "hidden",
    marginRight: -8, // for full width - to fit "CV" part of text
  },
  fileInputControlLabelFileSelected: {
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  additionalInformationFormControl: {
    width: "100%"
  },
  inFormBlockHeader: {
    marginTop: 0
  },
  underInfo: {
    fontSize: 12,
    color: "#9E9EA7",
  },
  termsText: {
    margin: "12px 0",
    textAlign: "justify"
  },
  backdrop: {
    zIndex: 1000
  },
  errorAlert: {
    marginBottom: 8
  }
});

export default useStyles;
